import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/balance-order";

export async function query(props: {
  customerUID?: string,
  status?: string,
  pakke?: string[],
  page?: number,
}): Promise<{
  docs: Learnlink.BalanceOrder.default.Order[],
  count: number,
}> {
  const urlParms = new URLSearchParams();

  if (props.page) urlParms.append("page", props.page.toString());
  if (props.customerUID) urlParms.append("search", props.customerUID);
  if (props.pakke) props.pakke.forEach((value) => urlParms.append("pakke", value));
  if (props.status) urlParms.append("status", props.status);

  const transactionRes = await axios.get(`${ENDPOINT}/query?${urlParms.toString()}`);
  return transactionRes.data;
}

export async function update(id: string, options: Learnlink.BalanceOrder.default.Update): Promise<Learnlink.BalanceOrder.default.Order> {
  return await axios.put(`${ENDPOINT}/${id}`, options);
}

export async function createInvoice(id: string): Promise<Learnlink.BalanceOrder.default.Order> {
  return await axios.post(`${ENDPOINT}/${id}/invoice`);
}

export async function cancelOrder(orderID: string, invoiceItemID: string):Promise<void> {
  if (invoiceItemID.length) {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}/${invoiceItemID}`,
    );
  }
  else {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}`,
    );
  }
}
